.grid {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  justify-content: center;
  gap: 40px;
}

.section {
  max-width: 480px;
  margin: 180px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
}

.input {
  display: flex;
}

.link {
  text-decoration: none;
  color: #4C4CFF;
}

@media screen and (max-width: 1300px) {
  .grid {
    grid-template-columns: unset;
  }
}
