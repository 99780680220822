.link {
  text-decoration: none;
  color: #8585ad;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.linkActive {
  color: #f2f2f3;
}
