.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
