.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
}

.buttons {
  align-self: flex-end;
}
