.section {
  display: flex;
  flex-direction: column;
}

.list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  overflow-y: auto;
  max-height: calc(100vh - 536px);
  height: 100%;
  transition: 0.5s;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.priceContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 750px) {
  .buttonContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 16px);
    justify-content: center;
    background-color: #1c1c21;
  }
}
