.page {
  width: 100%;
  height: 100%;
  min-width: 320px;
  margin: 0 auto;
  background-color: #131316;
  color: #F2F2F3;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.constructor-element__text {
  flex-grow: 1;
}

.input__container {
  flex-grow: 1;
}

.input_size_default {
  min-width: 100%;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 12px;
}

::-webkit-scrollbar-track:vertical {
  background: #2F2F37;
}

::-webkit-scrollbar-thumb:vertical {
  background: #8585AD;
  border-radius: 12px;
  box-sizing: border-box;
  border: 3px solid #2F2F37;
}
