.title {
  align-self: flex-start;
}

.list {
  padding-left: 0;
  margin-top: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.listItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
}

.itemText {
  composes: text text_type_main-default text_color_inactive from global;
}

.itemNumbers {
  composes: text text_type_digits-default text_color_inactive from global;
}

.image {
  min-height: 240px;
}
