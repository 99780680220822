.menu {
  list-style: none;
  padding-left: 0;
  margin-top: 80px;
  margin-bottom: 0;
}

.menuLink {
  min-height: 64px;
  display: flex;
  align-items: center;
}

.link {
  text-decoration: none;
  color: #8585ad;
  cursor: pointer;
}

.linkActive {
  color: #f2f2f3;
}
