.orders {
  display: flex;
  gap: 36px;
}

.ordersContainer {
  width: 45%;
}

.orderList {
  list-style: none;
  padding-left: 0;
  margin: 0;
  max-height: 320px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
